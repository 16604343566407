import type { AppEssentials, FedopsLogger } from '@wix/fe-essentials-viewer-platform'
import { createFedopsLogger } from '@wix/thunderbolt-commons'
import { platformBiLoggerFactory } from '../../bi/biLoggerFactory'
import { PlatformEnvData, SessionServiceAPI } from '@wix/thunderbolt-symbols'

export const createFedopsLoggerFactory = ({
	biData,
	viewMode,
	sessionService,
	appEssentials,
}: {
	biData: PlatformEnvData['bi']
	viewMode: 'site' | 'preview'
	sessionService: SessionServiceAPI
	appEssentials: AppEssentials
}): FedopsLogger =>
	createFedopsLogger({
		biLoggerFactory: platformBiLoggerFactory({ sessionService, factory: appEssentials.biLoggerFactory }).createBiLoggerFactoryForFedops(biData),
		customParams: {
			isMobileFriendly: biData.isMobileFriendly,
			viewerName: 'thunderbolt',
			viewMode,
		},
		factory: appEssentials.createFedopsLogger,
	})
